import React, { useEffect, useRef } from 'react';
import { setPreset } from '../three/src/main';
import { gsap } from 'gsap';
import TransitionLink from "gatsby-plugin-transition-link";
import * as indexStyles from './index.module.css';

import { Helmet } from "react-helmet"

const NotFoundPage = ({ transitionStatus }) => {
    const container = useRef(null);

    useEffect(() => {
        setPreset(0);
        gsap.to(container.current, { opacity: 1, duration: 1, ease: "power1.in", delay: 0.2 })
    }, [])

    useEffect(() => {
        if(transitionStatus === 'exiting') {
            gsap.to(container.current, { opacity: 0, duration: 1 })
        }
    }, [transitionStatus]);

    return (
        <>
            <Helmet>
                <title>404 | Luka Batista</title>
            </Helmet>
            <div className={indexStyles.container} ref={container}>
                <div className={indexStyles.box}>
                    <div className={indexStyles.content}>
                        This page does not exist.
                        <TransitionLink
                            to="/"
                            exit={{
                                length: 1,
                            }}
                            entry={{
                            }}
                        >
                            Go home.
                        </TransitionLink> 
                    </div>
                </div>
            </div>
        </>
    );
}

export default NotFoundPage;